<template>
    <div class="w-100">
        <table v-if="listData.length > 0"
               class="table table-head-fixed text-nowrap table-striped">
            <thead>
                <tr style="color: #1976d2; font-weight: 500">
                    <th class="text-nowrap" style="width: 1%">
                        <button style="font-weight: 600" @click="sortList('A.ID')">
                            <span>Id</span>
                            <span v-if="pagination.orderBy === 'A.ID'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap" style="width: 1%">
                        <button style="font-weight: 600" @click="sortList('A.USER_NAME')">
                            <span>Username</span>
                            <span v-if="pagination.orderBy === 'A.USER_NAME'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                        /
                        <button style="font-weight: 600" @click="sortList('ctry.COUNTRY_NAME')">
                            <span>Country</span>
                            <span v-if="pagination.orderBy === 'ctry.COUNTRY_NAME'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap">
                        <button style="font-weight: 600" @click="sortList('rsp.NAME')">
                            <span>Package</span>
                            <span v-if="pagination.orderBy === 'rsp.NAME'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap">
                        <button style="font-weight: 600" @click="sortList('A.RDG_ID')">
                            <span>Region ID</span>
                            <span v-if="pagination.orderBy === 'A.RDG_ID'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap">
                        <button style="font-weight: 600" @click="sortList('A.CLIENT_IP')">
                            <span>Client IP</span>
                            <span v-if="pagination.orderBy === 'A.CLIENT_IP'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                        /
                        <button style="font-weight: 600" @click="sortList('A.REMOTE_IP')">
                            <span>Remote IP</span>
                            <span v-if="pagination.orderBy === 'A.REMOTE_IP'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap">
                        <button style="font-weight: 600" @click="sortList('A.MACHINE_ID')">
                            <span>Machine</span>
                            <span v-if="pagination.orderBy === 'A.MACHINE_ID'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                        /
                        <button style="font-weight: 600" @click="sortList('A.REMOTE_MAC_ADDRESS')">
                            <span>MAC</span>
                            <span v-if="pagination.orderBy === 'A.REMOTE_MAC_ADDRESS'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap">
                        <span>State connect</span>
                    </th>
                    <th class="text-nowrap">
                        <button style="font-weight: 600" @click="sortList('A.START_TIME')">
                            <span>Start time</span>
                            <span v-if="pagination.orderBy === 'A.START_TIME'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-nowrap">
                        <button style="font-weight: 600" @click="sortList('A.CONNECT_DURATION')">
                            <span>Duration</span>
                            <span v-if="pagination.orderBy === 'A.CONNECT_DURATION'">
                                <CaretBottom v-if="pagination.directionSort === 'DESC'"
                                             :directionSort="pagination.directionSort" />
                                <CaretTop v-else />
                            </span>
                            <CaretBottom v-else />
                        </button>
                    </th>
                    <th class="text-right text-nowrap" style="width: 1%">
                        Staff
                    </th>
                </tr>
            </thead>
            <tbody class="text-muted">
                <tr :key="element.id" v-for="element in listData">
                    <td class="v-center text-nowrap">
                        {{element.id}}
                    </td>
                    <td class="v-center text-nowrap">
                        <div class="mb-1"><strong>{{element.rdpgwUsername}}</strong></div>
                        <div><img :src="`/flag/${element.region}.svg`" style="height: 1rem;"> {{element.countryName}}</div>
                    </td>
                    <td class="v-center text-nowrap">
                        <div><strong>{{element.packageName}}</strong></div>
                        <div><small class="text-muted">{{element.packageDescription}}</small></div>
                    </td>
                    <td class="v-center text-nowrap">
                        <div><i class="el-icon-map-location text-primary"></i> <strong>{{element.serverGatewayName}}</strong></div>
                        <div><small class="text-muted">{{element.rdgId}}</small></div>
                    </td>
                    <td class="v-center text-nowrap">
                        <div class="mb-1"><i class="el-icon-caret-left text-danger"></i> {{element.clientIp}}</div>
                        <div><i class="el-icon-caret-right text-success"></i> {{element.remoteIp}}</div>
                    </td>
                    <td class="v-center text-nowrap">
                        <div class="mb-1"><i class="el-icon-monitor text-primary"></i> {{element.machineId}}</div>
                        <div><small class="text-muted">{{element.remoteMacAddress}}</small></div>
                    </td>
                    <td class="v-center text-nowrap">
                        <strong v-if="element.isConnecting" class="text-success">
                            <i class="el-icon-check"></i> Connected
                        </strong>
                        <span v-else class="text-danger">
                            <i class="el-icon-close"></i> Disconnected
                        </span>
                    </td>
                    <td class="v-center text-nowrap">
                        <component :is="element.isConnecting ? 'strong' : 'span'">
                            <i class="el-icon-date"></i> {{element.startTime | pretyDateUtc}}
                        </component>
                    </td>
                    <td class="v-center text-nowrap">
                        <i class="el-icon-timer text-primary"></i> <strong>{{element.connectDuration | secondDuration}}</strong>
                    </td>
                    <td class="v-center text-nowrap">
                        <v-avatar size="2.5rem">
                            <img id="app-avatar"
                                 :src="`/img/avatars/${element.staffCare}.jpg`"
                                 onerror="this.src='/img/avatar5.png'" />
                        </v-avatar>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import CaretTop from "@/views/components/Icon/CaretTop.vue";
    import CaretBottom from "@/views/components/Icon/CaretBottom.vue";
    export default {
        components: {
            CaretTop,
            CaretBottom,
        },
        props: {
            listData: {
                type: Array,
                default() {
                    return [];
                }
            },
            pagination: {
                type: Object,
                default() {
                    return {};
                }
            },
        },
        methods: {
            sortList(colName) {
                this.$emit('sortList', colName);
            }
        }
    }
</script>